import { Component } from 'react';
import { navigate } from 'gatsby';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

class Demo extends Component {
  componentDidMount() {
    this.props.firebase
      .doSignInAnonymously()
      .then(() => {
        navigate(ROUTES.HOME);
      });
  }

  render() {
    return null;
  }
}

export default withFirebase(Demo);
