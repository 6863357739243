import React from 'react';

import Layout from '../components/layout';
import Demo from '../components/Demo';

export default () => (
  <Layout>
    <Demo />
  </Layout>
);
